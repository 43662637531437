import { FLASH_MIMETYPE, FUTURESPLASH_MIMETYPE, FLASH7_AND_8_MIMETYPE, FLASH_MOVIE_MIMETYPE, isBuiltInContextMenuVisible, isScriptAccessAllowed, isSwfFilename, RufflePlayer, } from "./ruffle-player";
import { registerElement } from "./register-element";
/**
 * A polyfill html element.
 *
 * This specific class tries to polyfill existing `<embed>` tags,
 * and should not be used. Prefer [[RufflePlayer]] instead.
 *
 * @internal
 */
export class RuffleEmbed extends RufflePlayer {
    /**
     * Constructs a new Ruffle flash player for insertion onto the page.
     *
     * This specific class tries to polyfill existing `<embed>` tags,
     * and should not be used. Prefer [[RufflePlayer]] instead.
     */
    constructor() {
        super();
    }
    /**
     * @ignore
     * @internal
     */
    connectedCallback() {
        var _a, _b, _c, _d, _e, _f, _g;
        super.connectedCallback();
        const src = this.attributes.getNamedItem("src");
        if (src) {
            const allowScriptAccess = (_b = (_a = this.attributes.getNamedItem("allowScriptAccess")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
            const menu = (_d = (_c = this.attributes.getNamedItem("menu")) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null;
            // Kick off the SWF download.
            this.load({
                url: src.value,
                allowScriptAccess: isScriptAccessAllowed(allowScriptAccess, src.value),
                parameters: (_e = this.attributes.getNamedItem("flashvars")) === null || _e === void 0 ? void 0 : _e.value,
                backgroundColor: (_f = this.attributes.getNamedItem("bgcolor")) === null || _f === void 0 ? void 0 : _f.value,
                base: (_g = this.attributes.getNamedItem("base")) === null || _g === void 0 ? void 0 : _g.value,
                menu: isBuiltInContextMenuVisible(menu),
            });
        }
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    get src() {
        var _a;
        return (_a = this.attributes.getNamedItem("src")) === null || _a === void 0 ? void 0 : _a.value;
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    set src(srcval) {
        if (srcval != undefined) {
            const attr = document.createAttribute("src");
            attr.value = srcval;
            this.attributes.setNamedItem(attr);
        }
        else {
            this.attributes.removeNamedItem("src");
        }
    }
    /**
     * @ignore
     * @internal
     */
    static get observedAttributes() {
        return ["src", "width", "height"];
    }
    /**
     * @ignore
     * @internal
     */
    attributeChangedCallback(name, oldValue, newValue) {
        var _a;
        super.attributeChangedCallback(name, oldValue, newValue);
        if (this.isConnected && name === "src") {
            let parameters;
            const flashvars = this.attributes.getNamedItem("flashvars");
            if (flashvars) {
                parameters = flashvars.value;
            }
            const src = this.attributes.getNamedItem("src");
            if (src) {
                this.load({
                    url: src.value,
                    parameters,
                    base: (_a = this.attributes.getNamedItem("base")) === null || _a === void 0 ? void 0 : _a.value,
                });
            }
        }
    }
    /**
     * Checks if the given element may be polyfilled with this one.
     *
     * @param elem Element to check.
     * @returns True if the element looks like a flash embed.
     */
    static isInterdictable(elem) {
        var _a;
        if (!elem.getAttribute("src")) {
            return false;
        }
        const type = (_a = elem.getAttribute("type")) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (type === FLASH_MIMETYPE.toLowerCase() ||
            type === FUTURESPLASH_MIMETYPE.toLowerCase() ||
            type === FLASH7_AND_8_MIMETYPE.toLowerCase() ||
            type === FLASH_MOVIE_MIMETYPE.toLowerCase()) {
            return true;
        }
        else if (type == null || type === "") {
            return isSwfFilename(elem.getAttribute("src"));
        }
        return false;
    }
    /**
     * Creates a RuffleEmbed that will polyfill and replace the given element.
     *
     * @param elem Element to replace.
     * @returns Created RuffleEmbed.
     */
    static fromNativeEmbedElement(elem) {
        const externalName = registerElement("ruffle-embed", RuffleEmbed);
        const ruffleObj = document.createElement(externalName);
        ruffleObj.copyElement(elem);
        return ruffleObj;
    }
}
